<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <Logo />

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat class="mx-auto" :loading="loading">

                <v-card-text>
                  <div class="logo">
                    <img src="@/assets/images/app/logo-pinosvl.png" alt="">
                  </div>
                </v-card-text>
               
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2 pt-4">
                    Ficha técnica 📋 
                  </p>
                </v-card-text>

                

                <v-card-text v-if="user">
                  <v-list class="transparent">
                    <v-list-item>
                      <v-list-item-subtitle>Nombre / alias:</v-list-item-subtitle>
                      <v-list-item-title class="text-right text-capitalize">
                        {{ user.detail.first_name }} {{ user.detail.last_name }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="user.document">
                      <v-list-item-subtitle>Vacuna antitetanica</v-list-item-subtitle>
                      <v-list-item-title class="text-right" v-if="user.document.tetanus_vaccine" >
                        <a :href="user.document.tetanus_vaccine" target="_blank" download>Descargar</a>
                      </v-list-item-title>
                      <v-list-item-title class="text-right" v-if="!user.document.tetanus_vaccine" >
                        <a href="#">Sin Archvio</a>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="user.document">
                      <v-list-item-subtitle>Vacuna Antihepatitis B</v-list-item-subtitle>
                      <v-list-item-title class="text-right" v-if="user.document.hepatitis_vaccine" >
                        <a :href="user.document.hepatitis_vaccine" target="_blank" download>Descargar</a>
                      </v-list-item-title>
                      <v-list-item-title class="text-right" v-if="!user.document.hepatitis_vaccine" >
                        <a href="#">Sin Archvio</a>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="user.document">
                      <v-list-item-subtitle>Vacuna Covid</v-list-item-subtitle>
                      <v-list-item-title class="text-right" v-if="user.document.covid_vaccine" >
                        <a :href="user.document.covid_vaccine" target="_blank" download>Descargar</a>
                      </v-list-item-title>
                      <v-list-item-title class="text-right" v-if="!user.document.covid_vaccine" >
                        <a href="#">Sin Archvio</a>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="user.document">
                      <v-list-item-subtitle>Poliza de seguros</v-list-item-subtitle>
                      <v-list-item-title class="text-right" v-if="user.police" >
                        <a :href="user.police" target="_blank" download>Descargar</a>
                      </v-list-item-title>
                      <v-list-item-title class="text-right" v-if="!user.police" >
                        <a href="#">Sin Archvio</a>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/general/Logo";

import { ref, getCurrentInstance, onMounted } from "@vue/composition-api";
import api from "@/services";
import router from "@/router";
import IconPendingOrCheck from '@/components/icons/IconPendingOrCheck.vue';

export default {
  components: {
    Logo,
    IconPendingOrCheck,
  },
  setup() {
    const vm = getCurrentInstance().proxy;

    const loading = ref(false);
    const user = ref(null);


    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const getUserValidation = async (token) => {
      onToggleLoading();

      try {
        const { data } = await api.getUserValidation(token);
        user.value = data.data;

      } catch (error) {
        vm.$alert("Imposible validar información", null, "error");
      } finally {
        onToggleLoading();
      }

    }

    onMounted(() => {
      const { token } = router.currentRoute.params;
      if(!token) return;
  
      getUserValidation(token);
    })

    return {
      loading,
      user,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/preset/preset/pages/auth.scss";

.logo{
  display: flex;
  justify-content: center;
  >img{
    max-width: 100px;
  }
}

</style>
