<template>
  <span>
    <icon-check v-if="value"/>
    <icon-pending v-else />
  </span>
</template>

<script>
import IconCheck from '@/components/icons/IconCheck.vue';
import IconPending from '@/components/icons/IconPending.vue';

export default {
  components: {
    IconCheck,
    IconPending
  },
  props: {
    value: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>

</style>